<template>
  <div class="app-box">
    <div class="screen-header">
      <x-screen-tab :bindYear="selectObject.year" :bindMonth="selectObject.month" @change="screenChange"></x-screen-tab>
    </div>
    <x-scroll-view :top="0.5" @scrollLoadData="scrollLoadData">
      <ul class="visit-list-box">
        <li v-for="(item,index) in xhrData" :key="index" @click="itemClick(item);">
          <p class="visit-list-item-title">{{item.customerName}}</p>
          <p class="visit-list-item-time">拜访时间：{{item.beginTime}}</p>
        </li>
      </ul>
      <x-no-data v-if="ajaxEnd&&!isNextPage&&xhrData.length>0"></x-no-data>
      <x-no-data-bg v-if="ajaxEnd&&xhrData.length<=0&&pageNum<=1"></x-no-data-bg>
    </x-scroll-view>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import noData from "@/components/noData";
import noDataBg from "@/components/noDataBg";
import screenTab from "@/components/screenTab";
import { queryList } from "@/api/visitRecord";
import { page } from "@/script/mixins/page";

export default {
  name: "x-visit-info-list",
  data () {
    return {
      type: 1,
      selectObject: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      }
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  mixins: [page],
  created () { },
  methods: {
    getDataList (isReset = true) {
      queryList({
        customerId: this.$route.params.customerId,
        year: this.selectObject.year,
        month: this.selectObject.month,
        type: this.type,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(xhr => {
        const listData = xhr.data.records;
        this.isNextPage = listData.length >= this.pageSize;
        isReset && (this.xhrData = []);
        listData.map(item => {
          this.xhrData.push(item);
        });
        this.ajaxEnd = true;
      });
    },
    itemClick (item) {
      this.$router.push({
        path: `/visitRecord/detail/${item.id}/1`
      });
    },
    screenChange (data) {
      this.selectObject.year = data.year.code;
      this.selectObject.month = data.month.code || null;
      this.pageNum = 1;
      this.ajaxEnd = false;
      this.getDataList();
    }
  },
  watch: {
    show: {
      handler (newVal) {
        newVal && !this.ajaxEnd && this.getDataList();
      },
      immediate: true
    },
    $route (to) {
      if (this.$store.getters.getRefresh && this.show && to.name == "customerCenterDetailIndex") {
        this.getDataList();
        this.$store.commit("setRefresh", false);
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [screenTab.name]: screenTab,
    [noData.name]: noData,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
.screen-header {
  position: relative;
  z-index: 1;
  box-shadow: 0.02rem 0.02rem 0.04rem 0 rgba(8, 62, 134, 0.07);
}
.visit-list-box {
  margin: auto 0.15rem auto 0.2rem;
}
.visit-list-box li {
  padding: 0.12rem 0;
  border-bottom: solid 0.01rem#EEF1F5;
}
.visit-list-item-title {
  font-size: 0.15rem;
  color: #30353d;
  margin-bottom: 0.05rem;
}
.visit-list-item-time {
  font-size: 0.13rem;
  color: #737885;
}
</style>
