import axios from "@/axios/axiosConfig";

export const listStaff = (params) => {
  return axios({
    url: `/staff/listStaff/${params.customerId}`,
    method: "post"
  });
};

export const querySubordinateList = (params) => {
  return axios({
    url: "/allocation/querySubordinateList",
    method: "post",
    data: params
  });
};
