<template>
  <div class="app-box">
    <x-search placeholder="请输入设备名称" @click.native="searchEvent"></x-search>
    <x-scroll-view :top="0.52">
      <template v-for="(item,index) in list">
        <dl v-if="item.listItem.length>0" :key="index" class="list-box">
          <dt>{{item.text}}</dt>
          <dd v-for="(obj,k) in item.listItem" :key="k" @click="itemEvent(item.id,obj.id);">{{obj.brandName}}</dd>
        </dl>
      </template>
      <x-no-data v-if="ajaxCount==list.length&&!isNullList"></x-no-data>
      <x-no-data-bg v-if="ajaxCount==list.length&&isNullList" :tip="isLeader?null:'还没有任何内容快新建一条吧~'"></x-no-data-bg>
      <i v-if="!isLeader" class="add-icon" @click="popupShow=!popupShow;"></i>
    </x-scroll-view>
    <mt-popup v-model="popupShow" position="bottom">
      <div class="popup-box">
        <div class="popup-header" @touchmove.prevent>
          <button @click="popupShow=false;">取消</button>
          <button @click="finishEvent">确定</button>
        </div>
        <mt-picker :slots="slots" value-key="name" @change="onValuesChange" @touchmove.native.stop.prevent></mt-picker>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import search from "@/components/customerCenter/search";
import noData from "@/components/noData";
import noDataBg from "@/components/noDataBg";
import { listHardware } from "@/api/customerCenter";
import { Popup, Picker } from "mint-ui";

export default {
  name: "x-hardwareInfo-info-list",
  data () {
    return {
      isNullList: true,
      list: [],
      slots: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }],
      popupShow: false,
      checkItem: null,
      isLeader: JSON.parse(sessionStorage.getItem("token")).isLeader,
      customerType: this.$route.params.customerType,
      ajaxCount: 0
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  created () { },
  methods: {
    onValuesChange (picker, values) {
      this.checkItem = values;
    },
    finishEvent () {
      this.popupShow = false;
      this.$router.push({
        path: `/customerCenter/hardwareInfo/edit/new/${this.$route.params.customerId}/${this.checkItem[0].code}/${this.customerType}`
      });
    },
    init () {
      const configJson = JSON.parse(sessionStorage.getItem("configJson")).HardwareTypeEnum;
      Object.keys(configJson).forEach(key => {
        this.slots[0].values.push({
          code: parseInt(key),
          name: configJson[key]
        });
        this.list.push({
          id: parseInt(key),
          text: configJson[key],
          listItem: []
        });
      });
      this.getDataList();
    },
    getDataList () {
      this.list.map(item => {
        listHardware({
          customerId: this.$route.params.customerId,
          hardwareType: item.id
        }).then(xhr => {
          item.listItem = xhr.data;
          this.ajaxCount += 1;
        });
      });
    },
    itemEvent (hardwareType, id) {
      this.$router.push({
        path: `/customerCenter/hardwareInfo/read/${id}/${hardwareType}/${this.customerType}`
      });
    },
    searchEvent () {
      this.$router.push({
        path: `/searchCustomerCenter/hardwareInfo/${this.$route.params.customerId}/${this.customerType}/${this.hardwareType}`
      });
    }
  },
  watch: {
    ajaxCount (newVal) {
      if (newVal == this.list.length) {
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].listItem.length > 0) {
            this.isNullList = false;
            break;
          }
        }
      }
    },
    show: {
      handler (newVal) {
        newVal && !this.ajaxCount && this.init();
      },
      immediate: true
    },
    $route (to) {
      if (this.$store.getters.getRefresh && this.show && to.name == "customerCenterDetailIndex") {
        this.getDataList();
        this.$store.commit("setRefresh", false);
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [noData.name]: noData,
    [noDataBg.name]: noDataBg,
    [Popup.name]: Popup,
    [search.name]: search,
    [Picker.name]: Picker
  }
};
</script>

<style scoped>
.scroll-box .list-box:first-of-type dt {
  padding: 0.05rem 0 0.12rem;
}
.list-box {
  margin: auto 0.15rem;
}
.list-box dt {
  padding: 0.2rem 0 0.12rem;
  font-size: 0.16rem;
  color: #737885;
  border-bottom: solid 0.01rem #eef1f5;
}
.list-box dd {
  border-bottom: solid 0.01rem #eef1f5;
  padding: 0.15rem 0 0.15rem 0.25rem;
  line-height: 1;
  background: url('~@images/brand_icon.png') no-repeat left center/0.18rem;
  font-size: 0.15rem;
  color: #30353d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
