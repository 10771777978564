<template>
  <div class="main-box">
    <div class="main-left">
      <p v-for="(item,index) in menuData" :key="index" class="menu-item" :class="{'menu-check':item.check}" @click="menuEvent(item);">{{item.text}}</p>
    </div>
    <div class="main-right">
      <x-base-info-read v-show="menuData[0].check" :show="menuData[0].check"></x-base-info-read>
      <x-staff-info-list v-show="menuData[1].check" :show="menuData[1].check"></x-staff-info-list>
      <x-hardwareInfo-info-list v-show="menuData[2].check" :show="menuData[2].check"></x-hardwareInfo-info-list>
      <x-drugs-info-list v-show="menuData[3].check" :show="menuData[3].check"></x-drugs-info-list>
      <x-visit-info-list v-show="menuData[4].check" :show="menuData[4].check"></x-visit-info-list>
    </div>
  </div>
</template>

<script>
import read from "@/page/customerCenter/detail/baseInfo/read";
import list from "@/page/customerCenter/detail/staffInfo/list";
import hardwareInfoList from "@/page/customerCenter/detail/hardwareInfo/list";
import drugsInfoList from "@/page/customerCenter/detail/drugsInfo/list";
import visitInfoList from "@/page/customerCenter/detail/visitInfo/list";
import { cachePage } from "@/script/mixins/cachePage";

export default {
  name: "customerCenterDetailIndex",
  data () {
    return {
      menuData: [{
        text: "基本信息",
        check: true
      }, {
        text: "员工信息",
        check: false
      }, {
        text: "硬件信息",
        check: false
      }, {
        text: "药品信息",
        check: false
      }, {
        text: "拜访信息",
        check: false
      }]
    };
  },
  mixins: [cachePage],
  created () { },
  methods: {
    menuEvent (item) {
      if (!item.check) {
        this.menuData.map(item => {
          this.$set(item, "check", false);
        });
        this.$set(item, "check", true);
      }
    }
  },
  components: {
    [read.name]: read,
    [list.name]: list,
    [hardwareInfoList.name]: hardwareInfoList,
    [drugsInfoList.name]: drugsInfoList,
    [visitInfoList.name]: visitInfoList
  }
};
</script>

<style scoped>
.main-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.main-left {
  position: absolute;
  left: 0;
  width: 0.9rem;
  top: 0;
  bottom: 0;
  background-color: #f6f8fb;
}
.main-right {
  position: absolute;
  left: 0.9rem;
  right: 0;
  top: 0;
  bottom: 0;
}
.menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.52rem;
  font-size: 0.15rem;
  color: #737885;
}
.menu-check {
  background-color: #ffffff;
  font-size: 0.16rem;
  color: #2b82f7;
}
</style>
