<template>
  <div class="app-box">
    <x-search placeholder="请输入药品名称或通用名" @click.native="searchEvent"></x-search>
    <x-scroll-view :top="0.52">
      <ul v-if="xhrData.length>0" class="drugs-list-box">
        <li v-for="(item,index) in xhrData" :key="index" class="drugs-list-item" @click="itemEvent(item.id);">
          <p class="drugs-title">
            <span>{{item.medicineName}}</span>
          </p>
          <p class="drugs-txt m-t-b-12">{{item.specification}}</p>
          <p class="drugs-txt">{{item.manufacturer}}</p>
        </li>
      </ul>
      <x-no-data v-if="ajaxEnd&&xhrData.length>0"></x-no-data>
      <x-no-data-bg v-if="ajaxEnd&&xhrData.length<=0" :tip="isLeader?null:'还没有任何内容快新建一条吧~'"></x-no-data-bg>
      <i v-if="!isLeader" class="add-icon" @click="addEvent"></i>
    </x-scroll-view>
  </div>
</template>

<script>
import search from "@/components/customerCenter/search";
import scrollView from "@/components/scrollView";
import noData from "@/components/noData";
import noDataBg from "@/components/noDataBg";
import { listMedicine } from "@/api/customerCenter";

export default {
  name: "x-drugs-info-list",
  data () {
    return {
      isLeader: JSON.parse(sessionStorage.getItem("token")).isLeader,
      customerType: this.$route.params.customerType,
      xhrData: [],
      ajaxEnd: false
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  created () { },
  methods: {
    searchEvent () {
      this.$router.push({
        path: `/searchCustomerCenter/drugsInfo/${this.$route.params.customerId}/${this.customerType}`
      });
    },
    getDataList () {
      listMedicine({
        customerId: this.$route.params.customerId
      }).then(xhr => {
        this.xhrData = xhr.data;
        this.ajaxEnd = true;
      });
    },
    itemEvent (id) {
      this.$router.push({
        path: `/customerCenter/drugsInfo/read/${this.customerType}/${id}`
      });
    },
    addEvent () {
      this.$router.push({
        path: `/customerCenter/drugsInfo/edit/new/${this.customerType}/${this.$route.params.customerId}`
      });
    }
  },
  watch: {
    show: {
      handler (newVal) {
        newVal && !this.ajaxEnd && this.getDataList();
      },
      immediate: true
    },
    $route (to) {
      if (this.$store.getters.getRefresh && this.show && to.name == "customerCenterDetailIndex") {
        this.getDataList();
        this.$store.commit("setRefresh", false);
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [search.name]: search,
    [noData.name]: noData,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
.drugs-list-box {
  width: 2.5rem;
  margin: auto;
}
.drugs-list-item {
  line-height: 1;
  padding: 0.15rem 0;
  border-bottom: solid 0.01rem #eef1f5;
}
.drugs-title {
  display: flex;
  align-items: center;
  height: 0.18rem;
  background: url('~@images/brand_icon.png') no-repeat 0 center/0.18rem;
  padding-left: 0.24rem;
}
.drugs-title span {
  font-size: 0.15rem;
  color: #30353d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.m-t-b-12 {
  margin: 0.12rem 0;
}
.drugs-txt {
  margin-left: 0.24rem;
  font-size: 0.13rem;
  color: #81848c;
}
</style>
