<template>
  <div class="app-box-scroll">
    <ul class="read-list">
      <li v-if="xhrData&&xhrData.orgName" class="read-item">
        <div class="read-title">机构名称</div>
        <div class="read-txt">{{xhrData.orgName}}</div>
      </li>
      <li v-if="cityArea" class="read-item">
        <div class="read-title">地区</div>
        <div class="read-txt">{{cityArea}}</div>
      </li>
      <li v-if="xhrData&&xhrData.address" class="read-item">
        <div class="read-title">详细地址</div>
        <div class="read-txt">{{xhrData.address}}</div>
      </li>
      <li v-if="xhrData&&xhrData.creditCode" class="read-item">
        <div class="read-title">社会统一信用代码</div>
        <div class="read-txt">{{xhrData.creditCode}}</div>
      </li>
      <li v-if="xhrData&&xhrData.establishDate" class="read-item">
        <div class="read-title">成立日期</div>
        <div class="read-txt">{{xhrData.establishDate}}</div>
      </li>
      <template v-if="customerType == 1000">
        <li v-if="xhrData&&xhrData.businessArea" class="read-item">
          <div class="read-title">营业面积（平方米）</div>
          <div class="read-txt">{{xhrData.businessArea}}</div>
        </li>
        <li v-if="xhrData&&xhrData.orgNature" class="read-item">
          <div class="read-title">机构性质</div>
          <div class="read-txt">{{xhrData.orgNature}}</div>
        </li>
        <!-- <li v-if="xhrData&&xhrData.otherOrgNature" class="read-item">
          <div class="read-title">其他机构性质</div>
          <div class="read-txt">{{xhrData.otherOrgNature}}</div>
        </li> -->
        <li v-if="xhrData&&xhrData.shopOpen!=null" class="read-item">
          <div class="read-title">国药商城是否开户</div>
          <div class="read-txt">{{xhrData.shopOpen|getEnum}}</div>
        </li>
        <li v-if="isClinic&&xhrData&&xhrData.clinic!=null" class="read-item">
          <div class="read-title">门诊部</div>
          <div class="read-txt">{{xhrData&&xhrData.clinic==1?"是":"否"}}</div>
        </li>
      </template>
      <template v-if="customerType == 2000">
        <li v-if="xhrData&&xhrData.staffNumber" class="read-item">
          <div class="read-title">员工数（人）</div>
          <div class="read-txt">{{xhrData.staffNumber}}</div>
        </li>
        <li v-if="xhrData&&xhrData.annualSales" class="read-item">
          <div class="read-title">年销售额（万元）</div>
          <div class="read-txt">{{xhrData.annualSales}}</div>
        </li>
        <li v-if="xhrData&&xhrData.chargeName" class="read-item">
          <div class="read-title">负责人姓名</div>
          <div class="read-txt">{{xhrData.chargeName}}</div>
        </li>
      </template>
    </ul>
    <i v-if="!isLeader" class="edit-icon" @click="editEvent"></i>
    <i v-if="!isLeader" class="visit-icon" @click="visitEvent"></i>
    <!-- <x-no-data></x-no-data> -->
  </div>
</template>

<script>
import noData from "@/components/noData";
import { getCustomer } from "@/api/customerCenter";
import { qqMapGeocoder } from "@/api/base";

export default {
  name: "x-base-info-read",
  data () {
    return {
      isLeader: JSON.parse(sessionStorage.getItem("token")).isLeader,
      customerType: this.$route.params.customerType,
      isClinic: false,
      cityArea: null,
      xhrData: null
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getData () {
      getCustomer({
        customerId: this.$route.params.customerId
      }).then(xhr => {
        this.xhrData = xhr.data;
        this.xhrData.orgNature && (this.isClinic = this.xhrData.orgNature.indexOf("公立 - 专科医院") >= 0);
        this.xhrData.latitude && this.xhrData.longitude && this.getCity();
        document.title = this.xhrData.orgName;
      });
    },
    getCity () {
      qqMapGeocoder({
        latitude: this.xhrData.latitude,
        longitude: this.xhrData.longitude
      }).then(xhr => {
        this.cityArea = `${xhr.data.result.address_component.province} ${xhr.data.result.address_component.city} ${xhr.data.result.address_component.district}`;
      });
    },
    editEvent () {
      this.$router.push({
        path: `/customerCenter/baseInfo/edit/edit/${this.customerType}/${this.xhrData.id}`
      });
    },
    visitEvent () {
      this.$router.push({
        path: `/visitRecord/edit/new/${this.xhrData.id}`
      });
    }
  },
  filters: {
    getEnum (value) {
      const configJson = JSON.parse(sessionStorage.getItem("configJson")).GuoyaoShopOpenStatusEnum;
      let returnTxt;
      Object.keys(configJson).forEach(key => {
        if (value == key) {
          returnTxt = configJson[key];
        }
      });
      return returnTxt;
    }
  },
  watch: {
    show: {
      handler (newVal) {
        newVal && !this.xhrData && this.getData();
      },
      immediate: true
    },
    $route (to) {
      if (this.$store.getters.getRefresh && this.show && to.name == "customerCenterDetailIndex") {
        this.getData();
        this.$store.commit("setRefresh", false);
      }
    }
  },
  components: {
    [noData.name]: noData
  }
};
</script>

<style scoped>
.read-list {
  width: 2.5rem;
  margin: auto;
}
.read-item {
  padding: 0.12rem 0;
  border-bottom: solid 0.01rem #eef1f5;
}
.read-item div {
  line-height: 1;
}
.read-title {
  margin-bottom: 0.08rem;
  font-size: 0.12rem;
  color: #737885;
}
.read-item .read-txt {
  font-size: 0.15rem;
  color: #30353d;
  line-height: 0.18rem;
}
.visit-icon {
  position: fixed;
  right: 0.24rem;
  width: 0.6rem;
  height: 0.6rem;
  bottom: 0.66rem;
  background: url('~@images/visit_icon.png') no-repeat center/0.6rem;
}
</style>
