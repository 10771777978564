<template>
  <div :class="[isFullScreen?'app-box':'index-box']">
    <x-no-data-bg v-if="!xhrData&&ajaxEnd" :tip="tip||'还没有任何内容快新建一条吧~'"></x-no-data-bg>
    <div v-if="xhrData" ref="content" class="list-index-scroll">
      <dl v-for="(item,index) in xhrData" :key="index" class="list-index-box">
        <dt class="item-index" ref="section">{{index}}</dt>
        <dd v-for="(i,j) in item" :key="j" class="item-txt" @click="itemEvent(i);">{{i.name}}</dd>
      </dl>
      <x-no-data></x-no-data>
    </div>
    <div v-if="xhrData" class="list-index-key-box" @touchmove.prevent @touchstart="handleTouchStart">
      <ul class="list-index-key">
        <li v-for="(item,index) in xhrData" :key="index" class="index-key">{{index}}</li>
      </ul>
    </div>
    <p class="max-key" v-show="moving">{{currentIndicator}}</p>
  </div>
</template>

<script>
import noData from "@/components/noData";
import { listStaff, querySubordinateList } from "@/api/listIndex";
import noDataBg from "@/components/noDataBg";

export default {
  name: "x-list-index",
  data () {
    return {
      ajaxEnd: false,
      currentIndicator: null,
      firstSection: null,
      indicatorTime: null,
      moving: false,
      xhrData: null
    };
  },
  props: {
    isFullScreen: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: true
    },
    isAssignment: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String,
      default: null
    }
  },
  mounted () { },
  methods: {
    getDataList () {
      if (this.isAssignment) {
        querySubordinateList({}).then(xhr => {
          this.ajaxBack(xhr);
        });
      } else {
        listStaff({
          customerId: this.$route.params.customerId
        }).then(xhr => {
          this.ajaxBack(xhr);
        });
      }
    },
    ajaxBack (xhr) {
      if (xhr.data.length > 0) {
        this.xhrData = new Object();
        xhr.data.map(item => {
          const temp = {
            id: item.id,
            name: item.name,
            birthday: item.birthday,
            fixedLineTelephone: item.fixedLineTelephone,
            hobby: item.hobby,
            major: item.major,
            majorType: item.majorType,
            orgId: item.orgId,
            otherMajor: item.otherMajor,
            otherPositon: item.otherPositon,
            positon: item.positon,
            positonType: item.positonType,
            surnameInitial: item.surnameInitial,
            telephone: item.telephone
          };
          if (this.xhrData[item.surnameInitial]) {
            this.xhrData[item.surnameInitial].push(temp);
          } else {
            this.xhrData[item.surnameInitial] = [temp];
          }
        });
        this.$nextTick(() => {
          const listItems = this.$refs.content.getElementsByTagName("dt");
          if (listItems.length > 0) {
            this.firstSection = listItems[0];
          }
        });
      } else {
        this.xhrData = null;
      }
      this.ajaxEnd = true;
    },
    handleTouchStart (e) {
      if (e.target.tagName !== "LI") {
        return;
      }
      this.scrollList(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
      this.indicatorTime && clearTimeout(this.indicatorTime);
      this.moving = true;
      window.addEventListener("touchmove", this.handleTouchMove);
      window.addEventListener("touchend", this.handleTouchEnd);
    },
    handleTouchMove (e) {
      this.scrollList(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
    },
    handleTouchEnd () {
      this.indicatorTime = setTimeout(() => {
        this.moving = false;
        this.currentIndicator = "";
      }, 500);
      window.removeEventListener("touchmove", this.handleTouchMove);
      window.removeEventListener("touchend", this.handleTouchEnd);
    },
    scrollList (x, y) {
      const currentItem = document.elementFromPoint(x, y);
      if (!currentItem || !currentItem.classList.contains("index-key")) {
        return;
      }
      this.currentIndicator = currentItem.innerText;
      const targets = this.$refs.section.filter(item => item.innerText === this.currentIndicator);
      targets.length > 0 && (this.$refs.content.scrollTop = targets[0].getBoundingClientRect().top - this.firstSection.getBoundingClientRect().top);
    },
    itemEvent (item) {
      this.$emit("itemClick", item);
    }
  },
  watch: {
    show: {
      handler (newVal) {
        newVal && !this.ajaxEnd && this.getDataList();
      },
      immediate: true
    }
  },
  components: {
    [noData.name]: noData,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
.index-box {
  position: absolute;
  top: 0.1rem;
  bottom: 0.1rem;
  left: 0.15rem;
  right: 0.15rem;
  border-radius: 0.08rem;
  overflow: hidden;
  background-color: #ffffff;
}
.list-index-scroll {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0.16rem;
  overflow: auto;
}
.list-index-box {
  background-color: #ffffff;
  padding-left: 0.16rem;
  line-height: 1;
}
.item-index {
  padding-top: 0.16rem;
  font-size: 0.18rem;
  color: #30353d;
}
.item-txt {
  padding: 0.16rem 0;
  font-size: 0.15rem;
  color: #30353d;
  border-bottom: solid 0.01rem #f6f8fb;
}
.max-key {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 0.32rem;
  color: #ffffff;
}
.list-index-key-box {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.list-index-key {
  max-height: 100%;
}
.index-key {
  width: 0.2rem;
  padding: 0.04rem 0;
  font-size: 0.14rem;
  color: #a7abb4;
  line-height: 1;
  text-align: center;
}
</style>
