<template>
  <div class="app-box">
    <x-search placeholder="请输入员工姓名" @click.native="searchEvent"></x-search>
    <div class="list-index-box">
      <x-list-index ref="listIndex" :isFullScreen="true" @itemClick="itemClick" :show="show"></x-list-index>
      <i v-if="!isLeader" class="add-icon" @click="addEvent"></i>
    </div>
  </div>
</template>

<script>
import listIndex from "@/components/listIndex";
import search from "@/components/customerCenter/search";

export default {
  name: "x-staff-info-list",
  data () {
    return {
      isLeader: JSON.parse(sessionStorage.getItem("token")).isLeader,
      ajaxEnd: false,
      xhrData: []
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  created () { },
  methods: {
    searchEvent () {
      this.$router.push({
        path: `/searchCustomerCenter/staffInfo/${this.$route.params.customerId}/${this.$route.params.customerType}`
      });
    },
    addEvent () {
      this.$router.push({
        path: `/customerCenter/staffInfo/edit/new/${this.$route.params.customerType}/${this.$route.params.customerId}`
      });
    },
    itemClick (item) {
      this.$router.push({
        path: `/customerCenter/staffInfo/read/${this.$route.params.customerType}/${item.id}`
      });
    }
  },
  watch: {
    $route (to) {
      if (this.$store.getters.getRefresh && this.show && to.name == "customerCenterDetailIndex") {
        this.$refs.listIndex.getDataList();
        this.$store.commit("setRefresh", false);
      }
    }
  },
  components: {
    [listIndex.name]: listIndex,
    [search.name]: search
  }
};
</script>

<style scoped>
.list-index-box {
  position: absolute;
  top: 0.52rem;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
